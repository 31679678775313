import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import "./projects.css";

const LatestProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      projects: allProjectsYaml(filter: { featured: { eq: true } }) {
        edges {
          node {
            title
            abstract
            description
            slug
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <section className="latest-projects-section">
        <h1>Latest projects</h1>
        <p>
          Here's a few of my latest side projects! You can find more on the{" "}
          <Link to={`/projects`}>projects page</Link>.
        </p>
      </section>
      <section className="projects">
        {data.projects.edges.map(({ node }, id) => (
          <div key={node.title} className="project">
            <Img fluid={node.image.childImageSharp.fluid} alt={node.alt} />
            <p>{node.abstract}</p>
            <section
              style={{
                zIndex: 10,
                position: "relative",
                width: `fit-content`,
                fontSize: `1em`,
              }}
            >
              <Link
                to={`/project/${node.slug}`}
                state={{ title: node.title }}
                className="project-link"
              >
                {node.title}
              </Link>
            </section>
          </div>
        ))}
      </section>
    </>
  );
};

export default LatestProjects;
